<template>
  <div class="bg-lovin h-100">
    <div class="container h-100">
      <h2 class="text-center"><router-link class="app-title" to="/admin">Ozzie Lovin</router-link></h2>
      <div class="h-100">
        <ul class="list-fields">
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckName`" v-model="edit_fields.show_name">
              <label class="custom-control-label text-white" :for="`customCheckName`">Name</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckBirthday`" v-model="edit_fields.show_birthday">
              <label class="custom-control-label text-white" :for="`customCheckBirthday`">Birthday</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckCountry`" v-model="edit_fields.show_country">
              <label class="custom-control-label text-white" :for="`customCheckCountry`">Country</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckEmail`" v-model="edit_fields.show_email">
              <label class="custom-control-label text-white" :for="`customCheckEmail`">Email</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckPhone`" v-model="edit_fields.show_phone">
              <label class="custom-control-label text-white" :for="`customCheckPhone`">Phone #</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" :id="`customCheckQuestion`" v-model="edit_fields.show_question">
              <label class="custom-control-label text-white" :for="`customCheckQuestion`">Question</label>
            </div>
          </li>
        </ul>

        <div class="d-flex justify-content-center" style="margin-top: 6rem">
          <button :disabled="loading" type="button" class="btn btn-secondary btn-style1" @click="handleSave()">{{ loading? "Saving...": "Save" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import OzzieLovin from "@/models/Ozzielovin";

export default {
  name: "EditFields",
  data() {
    return {
      edit_fields: new OzzieLovin(),
      loading: false
    }
  },
  watch: {
    fields: {
      handler(val) {
        this.edit_fields = val;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState([
        'fields'
    ]),
  },
  created() {
    this.$store.dispatch('getDataFields')
  },
  methods: {
    handleSave() {
      let that = this;
      that.loading = true
      this.edit_fields.save().then(() => {
        that.loading = false;
      }).catch((e) => {
        alert("Unable to save data. Please try again");
        console.log(e)
      });
    }
  }
}
</script>

<style scoped lang="scss">
.list-fields {
  list-style: none;
  padding-left: 15px;
  margin-top: 5rem;
  li {
    margin-bottom: 30px;
  }
}
.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #FFDD2B;
  border-color: #FFDD2B;
}
</style>
